export const Host = 'https://baghekhatina.app/bakhekhatina/api/private/index.php';
export const ImageHost = 'https://baghekhatina.app/bakhekhatina/images/';
export const MainHost = 'https://baghekhatina.app/';

//export const Host = 'http://paket.germanywestcentral.cloudapp.azure.com/bakhekhatina/api/private/index.php';
//export const ImageHost = 'http://paket.germanywestcentral.cloudapp.azure.com/bakhekhatina/images/';
//export const MainHost = 'http://paket.germanywestcentral.cloudapp.azure.com/';
//20.218.99.255

// export const Host = 'http://localhost/bakhekhatina/api/private/index.php';
// export const ImageHost = 'http://localhost/bakhekhatina/images/';
// export const MainHost = 'http://localhost/';
