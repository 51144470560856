import { DatatableWrapper, Filter, Pagination, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

export const DataTable = ({ body, headers }) => {
	return (
		<div
			className=""
			style={{ overflowX: 'auto' }}>
			<DatatableWrapper
				body={body}
				headers={headers}
				paginationOptionsProps={{
					initialState: {
						rowsPerPage: 15,
					},
				}}>
				<Row className="pb-3 d-flex flex-col justify-content-end g-0">
					<Col className="col-3">
						<Filter classes={{ clearButton: 'btn-dark btn-sm', input: 'form-control-sm' }} />
					</Col>
				</Row>
				<Table>
					<TableHeader />
					<TableBody />
				</Table>
				<Row className="pt-3 g-0">
					<Col className="d-flex flex-col justify-content-end">
						<Pagination classes={{ button: 'btn-dark btn-sm' }} />
					</Col>
				</Row>
			</DatatableWrapper>
		</div>
	);
};
