import { useState } from 'react';
import toast from 'react-hot-toast';
import { ButtonModal } from '../../components/modals/ButtonModal';
import { CustomSelect } from '../../components/select/CustomSelect';
import { Host } from '../../Config';

export function EditItem({ state, changeState, refresh, item, brands }) {
	const [arabic, setArabic] = useState(item.ar_name);
	const [kurdish, setKurdish] = useState(item.kr_name);
	const [english, setEnglish] = useState(item.en_name);

	const [brand, setBrand] = useState(item.brand);
	const [price1, setPrice1] = useState(item.price1);
	const [price2, setPrice2] = useState(item.price2);
	const [price3, setPrice3] = useState(item.price3);

	const [limit, setLimit] = useState(item.limit);

	const [arabic_desc, setArabicDesc] = useState(item.ar_description);
	const [kurdish_desc, setKurdishDesc] = useState(item.kr_description);
	const [english_desc, setEnglishDesc] = useState(item.en_description);

	return (
		<ButtonModal
			title="تعديل المادة"
			btnText="تعديل"
			state={state}
			changeState={changeState}
			btnAction={() => {
				insert(item.id, arabic, kurdish, english, price1, price2, price3, limit, brand, arabic_desc, kurdish_desc, english_desc);
			}}>
			<div className="mb-3">
				<CustomSelect
					options={brands}
					handleChange={setBrand}
					defaultValue={brand}
				/>
			</div>

			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={arabic}
				placeholder="عربي"
				onChange={(e) => setArabic(e.target.value)}
			/>
			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={kurdish}
				placeholder="كردي"
				onChange={(e) => setKurdish(e.target.value)}
			/>
			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={english}
				placeholder="انكليزي"
				onChange={(e) => setEnglish(e.target.value)}
			/>

			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={arabic_desc}
				placeholder="الوصف العربي"
				onChange={(e) => setArabicDesc(e.target.value)}
			/>
			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={kurdish_desc}
				placeholder="الوصف الكردي"
				onChange={(e) => setKurdishDesc(e.target.value)}
			/>
			<input
				type="text"
				className="form-control form-control-sm mb-3"
				value={english_desc}
				placeholder="الوصف الانكليزي"
				onChange={(e) => setEnglishDesc(e.target.value)}
			/>

			<input
				type="number"
				className="form-control form-control-sm mb-3"
				value={price1}
				placeholder="سعر 1"
				onChange={(e) => setPrice1(e.target.value)}
			/>
			<input
				type="number"
				className="form-control form-control-sm mb-3"
				value={price2}
				placeholder="سعر 2"
				onChange={(e) => setPrice2(e.target.value)}
			/>
			<input
				type="number"
				className="form-control form-control-sm mb-3"
				value={price3}
				placeholder="سعر 3"
				onChange={(e) => setPrice3(e.target.value)}
			/>

			<input
				type="number"
				min={1}
				className="form-control form-control-sm"
				value={limit}
				placeholder="الحد الاقصي"
				onChange={(e) => setLimit(e.target.value)}
			/>
		</ButtonModal>
	);

	function insert(id, ar, kr, en, price1, price2, price3, limit, brand, ar_desc, kr_desc, en_desc) {
		const data = {
			route: 'edit_item',
			ar_desc: ar_desc,
			kr_desc: kr_desc,
			en_desc: en_desc,
			arabic: ar,
			kurdish: kr,
			english: en,
			price1: price1,
			price2: price2,
			price3: price3,
			limit: limit,
			brand: brand,
			id: id,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}
		const token = localStorage.getItem('token');

		fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		})
			.then((response) => {
				response.json().then((json) => {
					if (json.status.code === 1) {
						toast.success('تم تعديل المادة');
						refresh();
					} else {
						toast.error('حذث خطاء ما');
					}
				});
			})
			.catch(() => {
				toast.error('حذث خطاء ما');
			});
	}
}
