import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { CustomSelect } from '../../components/select/CustomSelect';
import { Host, MainHost } from '../../Config';

export function Export() {
	const [barcodes, setBarcodes] = useState();
	const [brands, setBrands] = useState(null);
	const [brand, setBrand] = useState(-1);
	useEffect(() => {
		//fetchDollar();
		fetchBrands();
	}, []);
	return (
		<>
			<div className="pt-3 pe-3 ps-3 pb-3">
				<div className="w-100">
					<div className="">
						<div className="card">
							<div className="card-header">
								<div className="row align-items-center">
									<div className="col fw-bold">باركود</div>
								</div>
							</div>
							<div className="card-body">
								<div className="">
									<div className="mb-3 d-flex">
										<div className="w-100 me-3">
											<CustomSelect
												options={brands}
												handleChange={setBrand}
												defaultValue={brand}
											/>
										</div>
										<button
											className="btn btn-success"
											onClick={() => fetchBarcodes(brand)}>
											<i class="bi bi-download"></i>
										</button>
									</div>
									<textarea
										rows={20}
										className="form-control form-control-sm mb-3"
										value={barcodes}
										placeholder="باركود"
										onChange={(e) => setBarcodes(e.target.value)}
									/>
									<button
										className="btn btn-dark w-100 mt-3"
										onClick={() => {
											extract(barcodes);
										}}>
										استخراج
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Toaster position="top-center" />
		</>
	);

	async function fetchBarcodes(brand) {
		const data = {
			route: 'get_barcodes_brand',
			brand: brand,
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}
		const token = localStorage.getItem('token');

		var response = await fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		});

		var categories = await response.json();
		if (categories.status.code === 1) {
			setBarcodes(categories.data.join('\n'));
		} else {
			setBrands([]);
		}
	}

	async function fetchBrands() {
		const data = {
			route: 'fetch_brands',
		};

		if (!localStorage || !localStorage.getItem('token')) {
			return;
		}
		const token = localStorage.getItem('token');

		var response = await fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		});

		var categories = await response.json();
		if (categories.status.code === 1) {
			const newData = [];
			categories.data.forEach((e) => {
				newData.push({ value: e.id, label: e.en_name });
			});
			setBrands(newData);
		} else {
			setBrands([]);
		}
	}

	function extract(barcodes) {
		const data = {
			route: 'get_barcodes_info',
			barcodes: barcodes.split('\n'),
		};

		fetch(Host, {
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'post',
			mode: 'cors',
		})
			.then((response) => {
				response.json().then((json) => {
					if (json.status.code === 1) {
						toast.success('انتظر قليلا');
						const link = document.createElement('a');
						link.href = MainHost + 'bakhekhatina/images/export.zip' + '?t=' + new Date().getTime();
						link.target = '_blank';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					} else {
						toast.error('حذث خطاء ما');
					}
				});
			})
			.catch(() => {
				toast.error('حذث خطاء ما');
			});
	}
}
